<template>
  <v-container fluid style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'employees'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('employee.create.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="customerCreate"
            v-if="mxware.can('employee-create')"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
<!--          {{ $t('customer.buttons.save') }}-->
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('employee.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                :label="$t('employee.first_name')"
                                :placeholder="$t('employee.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                :label="$t('employee.last_name')"
                                :placeholder="$t('employee.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('employee.gender.title')"
                            :items="gender_select"
                            outlined
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.birthday"
                                outlined
                                hide-details
                                type="date"
                                :label="$t('employee.birthday')"
                                :placeholder="$t('employee.birthday')"
                                required
                                :error="errors.birthday!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('employee.email')"
                                :placeholder="$t('employee.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('employee.position')"
                                :placeholder="$t('employee.position')"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card>
          <v-card-title>{{ $t('employee.titles.avatar') }}</v-card-title>
          <v-card-text class="d-flex align-center">
            <v-avatar size="96">
              <v-icon size="96">mdi-account</v-icon>
            </v-avatar>
            <v-btn class="mt-1 ml-4">
              {{ $t('employee.buttons.avatar_create') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      preloader: true,
      form: {
        identification: this.$t('employee.create.identification'),
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        gender: 'female',
        is_deleted: 0,
        email_send: 0,
      },
      gender_select: [
        {
          text: this.$t('employee.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('employee.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('employee.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('employee.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    setTimeout(() => {
      this.preloader = false
    }, 300)
  },
  methods: {
    getPassword(password) {
      this.form.password = password
    },
    customerCreate() {
      this.$store.dispatch('employeeCreate', this.form).then(() => {
        this.$router.replace({
          name: 'employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
